<template>
    <div class="auth-bg bg bg-cover bg-pos-c">
        <div class="container">
            <div class="min-h-100vh fl-x fl-x-cc">
                <card border-radius="2" p="0" shadow="5" of="h" width="55r">
                    <row>
                        <col-12 lg="5">
                            <div class="py-7 bg-fifth fl-y-cc">
                                <div class="text-white">Welcome To</div>
                                <div class="logo">
                                    <img src="../../assets/img/logo/logo-lg.png" alt="">
                                </div>
                            </div>
                        </col-12>
                        <col-12 lg="7">
                            <s-form class="p-4" @submit="loginClicked">
                                <row>
                                    <col-12>
                                        <text-el color="2" fs="3" font-weight="500">Login</text-el>
                                    </col-12>
                                    <col-12>
                                        <validated-input border-radius="1" v-model="model.username"
                                                         placeholder="Username" :rules="{required:true}"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <validated-input border-radius="1" v-model="model.password" placeholder="Password" type="password"
                                                         :rules="{required:true}"></validated-input>
                                    </col-12>
                                    <col-12>
                                        <p v-if="invalidCredential" class="text-danger">{{ errorMessage }}</p>
                                    </col-12>
                                    <col-12 lg="6">
                                        <btn block color="fifth" type="submit">LOGIN</btn>
                                    </col-12>
                                    <col-12 lg="6">
                                        <btn block design="basic-f" @click="SetGoogleLogin">Continue with Google</btn>
                                    </col-12>
                                </row>
                            </s-form>
                        </col-12>
                    </row>
                </card>
            </div>
        </div>
        <modal ref="continueWithGoogleModal" title=" ">
            <div class="fl-j-c fl-x py-2">
                <img src="../../assets/img/logo/logo-lg.png" alt="">
            </div>
            <s-form @submit="GoogleLoginSubmit" class="px-5 pb-2" :save-url="googleLoginUrl" @failure="GoogleLoginFailure" @success="GoogleLoginFormSuccess('A')"
                    v-slot="{model, loading}">
                <row>
                    <col-12>
                        <validated-input placeholder="Enter your email id" class="mt-4"
                                         v-model="googleLoginModel.email" :rules="googleLoginFormRules.email" :disabled="loading"></validated-input>
                    </col-12>
                    <col-12>
                        <!--                        <div class="text-danger" v-if="!validEmail">Enter a Valid Email Address</div>-->
                        <div class="text-danger mb-2" v-if="googleLoginErrorMessage!=='' && googleLoginModel.email !==''">{{googleLoginErrorMessage}}</div>
                    </col-12>
                    <col-12>
                        <div class="text-center">
                            <submit-btn border-radius="1" type="submit" class="px-5" loading-text="Submitting..." :disabled="loading"
                                        :loading="loading"></submit-btn>
                        </div>
                    </col-12>
                </row>
            </s-form>
        </modal>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import urls from '../../data/urls';
import axios from 'secure-axios';
// import store from '@/store';

export default {
    name: 'LoginPage',
    components: {},
    data () {
        return {
            loginUrl: urls.auth.login,
            googleLoginUrl: urls.auth.googleLogin,
            invalidCredential: false,
            message: '',
            model: {
                username: '',
                password: ''
            },
            googleLoginFormRules: {
                email: {
                    required: true
                }
            },
            googleLoginModel: {
                email: ''
            },
            validEmail: true,
            googleLoginErrorMessage: ''
        };
    },
    methods: {
        ...mapActions(['setUser']),
        async loginClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(this.loginUrl, this.model);
            const json = response.data;
            console.log('data', json);
            if (json.user) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                that.setUser(json.user);
                if (json.user.password_flag === false && !json.user.admin) {
                    await that.$router.push(redirectUrl || { path: '/set-new-password/' });
                } else {
                    let dashboardPath = '';
                    if (json.user.admin) {
                        dashboardPath = '/dashboard/';
                    } else {
                        dashboardPath = '/animal-page/';
                    }
                    await that.$router.push(redirectUrl || { path: dashboardPath }).then(() => {
                        window.location.reload(true);
                    });
                }
                that.loading = false;
            } else {
                this.invalidCredential = true;
                that.errorMessage = 'Invalid Credentials';
                that.loading = false;
            }
            that.loading = false;
            localStorage.clear();
        },

        /*     async onSuccess (json) {
                            // after successful login
                            const username = json.user;
                            console.log('username:', json, username);
                            this.setUser(username);
                            // store.dispatch('setUser', username);
                            // store.commit('setUser', json.data.user);
                            const currentRoute = this.$route.path;
                            if (currentRoute !== '/dashboard/') {
                                await this.$router.push({ path: '/dashboard/' });
                            }
                        },
                        async validateCredentials () {
                            const that = this;
                            this.loading = true;
                            this.invalidCredential = false;
                            const response = await axios.form(
                                urls.auth.login, this.model, ['username', 'password']
                            ).catch(function (exception) {
                                that.serverError();
                            });

                            const json = response.data;
                            if (json.success) {
                                console.log('login success', json, json.success);
                                await this.onSuccess(json);
                            }
                            if (json.error === true) {
                                this.invalidCredentials(json);
                            } else if (json.error === false) {
                                this.validCredentials();
                            } else {
                                this.invalidCredentials(json);
                            }
                            this.loading = false;
                        },
                        serverError () {
                            this.invalidCredential = true;
                            this.message = 'Server error, Please try again later.';
                        },
                        invalidCredentials (json) {
                            this.invalidCredential = true;
                            // this.message = json.errors.__all__ || 'Invalid Credentials';
                        },
                        validCredentials () {
                            // console.log(data);
                            // this.$router.push('/');
                            const currentRoute = this.$route.path;
                            if (currentRoute !== '/dashboard/') {
                                this.$router.push({ path: '/dashboard/' });
                            }
                        }, */
        GoogleLoginFormSuccess (action, response) {
            console.log('login success', response);
            this.$refs.continueWithGoogleModal.close();
            if (action === 'A') {
                // this.$notify('Category Added Successfully', 'Success', {
                //     type: 'success'
                // });
                // this.$refs.table.refreshTable();
                // this.$router.push('/dashboard/');
                // window.open('https://vetuat.xeoscript.com/user/home/', '_self');
                window.open('/dashboard/', '_self');
            }
            // else {
            //     this.$notify('Category Updated Successfully', 'Success', {
            //         type: 'success'
            //     });
            // }
            this.$router.push('/dashboard/');
            // this.$refs.table.refreshTable();
            // this.editingItem = null;
        },
        async GoogleLoginFailure (response) {
            // this.$refs.continueWithGoogleModal.close();
            const errors = await response.data.errors;
            console.log('google login error', response.data.errors);
            if (errors.email === 'Enter a valid email address.') {
                this.validEmail = false;
            }
            // alert(response.data);
            // this.$notify(response.data.errors.email, 'Warning', { type: 'warning' });
            // for (const key in errors) {
            //     if (Object.prototype.hasOwnProperty.call(errors, key)) {
            //         const errorMessage = `${key}:  ${errors[key]}`;
            //         this.$notify(errorMessage, 'Warning', { type: 'warning' });
            //     }
            // }
        },
        SetGoogleLogin () {
            this.$refs.continueWithGoogleModal.show();
        },
        async GoogleLoginSubmit () {
            const that = this;
            try {
                this.response = await axios.form(this.googleLoginUrl, this.googleLoginModel);
                console.log('response', this.response);
                const result = this.response.data;
                if (result.user) {
                    // if (result.success) {
                    this.setUser(result.user);
                    setTimeout(() => {
                        // this.$router.push('/animal-page/');
                        window.open('/animal-page/', '_self');
                    }, 1000);
                    // window.open('/animal-page/', '_self');
                } else {
                    // const errors = this.response.data.errors;
                    console.log('errors', result.message);
                    this.googleLoginErrorMessage = result.message;
                    that.$notify(result.message, 'Warning', { type: 'warning' });
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
            }
        }
    }
};
</script>
<style lang="scss">
    .auth-bg {
        background-image: url("../../assets/img/auth-bg.jpg");
    }
</style>
